@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Fira Code', sans-serif;
  overflow-x: hidden;
}

.black{
  color: #171717;
}

.blackbg{
  background-color: #35cc58;
}